<template>
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav -->
            <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10" v-if="detail.is_presence == 2">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <img :src="detail.photo" width="200" alt="" />
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body -->
            <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
              <!--begin: Wizard Form-->
              <div class="row">
                <div class="offset-xxl-2 col-xxl-8">
                  <form class="form" id="kt_form">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <!-- <h4 class="font-weight-bold text-dark">
                        {{ detail.name }}
                      </h4> -->

                      <div class="card-body">
                        <table class="table table-borderless">
                          <tr>
                            <th class="pr-1" width="130">
                              <span class="font-weight-bold">Nama</span>
                            </th>
                            <th class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </th>
                            <td class="pl-2">
                              <span class="">{{ detail.created_by_name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th class="pr-1" width="130">
                              <span class="font-weight-bold" v-if="detail.is_presence == 1">Waktu Izin</span>
                              <span class="font-weight-bold" v-if="detail.is_presence == 2">Waktu Presensi</span>
                            </th>
                            <th class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </th>
                            <td class="pl-2">
                              <span class="" v-if="detail.is_presence == 2">{{ convertDate(detail.date) }}</span>
                              <span class="" v-if="detail.is_presence == 1">{{ setDate(detail.date) }}</span>
                            </td>
                          </tr>
                          <tr v-if="detail.is_presence == 1">
                            <th class="pr-1" width="130">
                              <span class="font-weight-bold">Keterangan</span>
                            </th>
                            <th class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </th>
                            <td class="pl-2">
                              <span class="">{{detail.notes}}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="primary"
                          @click="$router.push('/presence')"
                        >
                          Tutup
                        </b-button>
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="success"
                          @click="
                            $router.push(
                              '/presence/edit/' + $route.params.id
                            )
                          "
                          v-if="detail.is_presence == 2"
                        >
                          Edit
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                </div>
                <!--end: Wizard-->
              </div>
            </div>
            <!--end: Wizard Body -->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";

export default {
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    get() {
      ApiService.get("api/student-presences/" + this.$route.params.id)
        .then((response) => {
          this.detail = response.data.data;
          //("detail", this.detail)
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    convertDate(date){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(date).toLocaleDateString("id-ID", options)
      return localdata + " " + date.split(' ')[1]
    },
    setDate(date){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(date).toLocaleDateString("id-ID", options)
      return localdata
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kehadiran", route: "/presence" },
      { title: "Detail Kehadiran" },
    ]);

    this.get();
  },
};
</script>
